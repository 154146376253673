import './App.css';
import StarRating from "./components/StarRating";

export const apiUrl = "https://featbee.theseems.ru/api";
export const hCaptcha = {
    enabled: true,
    sitekey: "9072921c-0279-4cdd-afa7-2b7b63ef0d49"
}

function App() {
    return (
        <div className="App">
            <StarRating/>
        </div>
    );
}

export default App;
